.experiences__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.experience {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.experience:hover{
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
}

.experience__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.experience__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}

.experience__head h5 {
    text-align: center;
    color: whitesmoke;
    margin: 5px;
}

.experience__list {
    padding: 2rem;
}

.experience__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.experience__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.experience__list p {
    font-size: 0.9rem;
}


/* MD Devices*/
@media screen and (max-width: 1024px) {
    .experiences__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .experience {
        height: auto;
    }

}

/* SM Devices*/
@media screen and (max-width: 600px) {
    .experiences__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

}
